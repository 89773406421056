
const poems =[{
    title: "Poem 1",
    author: "Jim Darrough",
    poem: " Spicy jalapeno bacon ipsum dolor amet boudin brisket salami, ribeye sausage doner cupim prosciutto chislic bacon frankfurter capicola short loin ham short ribs. Ham hock filet mignon burgdoggen flank. Tail beef ribs strip steak doner boudin beef short loin ball tip jerky ribeye filet mignon. Pastrami porchetta bresaola, frankfurter landjaeger spare ribs drumstick picanha swine beef ribs shank jerky sirloin. Picanha short ribs tail boudin hamburger. Bacon rump pork chop, andouille beef t-bone strip steak leberkas doner cupim tri-tip ham hock jowl jerky. Biltong venison kevin, brisket turkey pancetta beef ribs t-bone."
}, 
{  title: "Poem 2",
author: "Jim Darrough",
poem: " Spicy jalapeno bacon ipsum dolor amet boudin brisket salami, ribeye sausage doner cupim prosciutto chislic bacon frankfurter capicola short loin ham short ribs. Ham hock filet mignon burgdoggen flank. Tail beef ribs strip steak doner boudin beef short loin ball tip jerky ribeye filet mignon. Pastrami porchetta bresaola, frankfurter landjaeger spare ribs drumstick picanha swine beef ribs shank jerky sirloin. Picanha short ribs tail boudin hamburger. Bacon rump pork chop, andouille beef t-bone strip steak leberkas doner cupim tri-tip ham hock jowl jerky. Biltong venison kevin, brisket turkey pancetta beef ribs t-bone."

},
{ title: "Poem 3",
author: "Jim Darrough",
poem: " Spicy jalapeno bacon ipsum dolor amet boudin brisket salami, ribeye sausage doner cupim prosciutto chislic bacon frankfurter capicola short loin ham short ribs. Ham hock filet mignon burgdoggen flank. Tail beef ribs strip steak doner boudin beef short loin ball tip jerky ribeye filet mignon. Pastrami porchetta bresaola, frankfurter landjaeger spare ribs drumstick picanha swine beef ribs shank jerky sirloin. Picanha short ribs tail boudin hamburger. Bacon rump pork chop, andouille beef t-bone strip steak leberkas doner cupim tri-tip ham hock jowl jerky. Biltong venison kevin, brisket turkey pancetta beef ribs t-bone."}


]


export default poems
